import { graphql } from 'gatsby';
import { createGatsbyPageComponent } from '@shapeable/web';
import { PostLayout } from '../../components/entities/post-layout';

export default createGatsbyPageComponent('Post', { layout: PostLayout });

export const QUERY = graphql`
  query PostQuery($id: ID!) {
    platform {
      post(id: $id) {
        id slug path 
        name
        title 
        pretitle
        subtitle
        published
        content { plain text }
        openGraph { title description { plain } image { thumbnails { full { url } } } }
        intro { plain text }
        outro { text }
        videos {
          id name url
          thumbnail { id url type  static { childImageSharp { gatsbyImageData } } }
        }
        imageAssets {
          id name image { id url type static { childImageSharp { gatsbyImageData } } }
        }
        organisations {
          id name slug path
          logo { id url type static { childImageSharp { gatsbyImageData } } }
        }
        people {
          id name slug path
          organisation { id name }
          linkedin
          position
          photo { id url type static { childImageSharp { gatsbyImageData } } }
        }
        embeds {
          citations {
            slug url name authors { id name slug } authorShowsEtAl edition publication date accessDate startPage volume footnoteNumber year
          }
          pages {
            id name slug path
            openGraph {
              title description { plain }
              image { url type static { childImageSharp { gatsbyImageData(placeholder: NONE) } } }
            }
          }
          people {
            id name slug path
            organisation { id name }
            position
            photo { id url }
          }
          imageAssets {
            id
            slug
            image {
              id url width height type static { childImageSharp { gatsbyImageData(placeholder: NONE) } } 
            }
          }
        }
        
        banners {
          id name slug title description { text } alternateText
          image { 
            id url width height type static { childImageSharp { gatsbyImageData(placeholder: NONE) } } 
          }
        }
      }
    }
  }
`;
